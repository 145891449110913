<template>
  <div class="about">
    <p>Wir arbeiten daran, durch Analyse der in einem Bienenstock herrschenden Summgeräusche zu ermitteln, ob sich das Volk in Arbeits- oder in Schwarmstimmung befindet.</p>

    <p>Dieser Ansatz ist nicht neu.</p>

    <p>Eddie Woods, ein britischer Imker und Toningenieur, hat sich damit schon vor ca. 70 Jahren befasst und ein Gerät (Apidictor) entwickelt, dass nach seinen Angaben eine Trefferquote von ca. 90 Prozent hatte, sich aber am Markt nicht durchgesetzt hat.</p>

    <p>Nähere Informationen dazu findet man unter den folgenden Links:
    <ul>
      <li><a href="http://www.immenfreunde.de/docs/EddieWoods.pdf">EddieWoods.pdf (immenfreunde.de)</a></li>
      <li><a href="https://beedata.com.mirror.hiveeyes.org/data2/listen/listenbees.htm">Listen to the Bees (hiveeyes.org)</a></li>
      <li><a href="https://soundcloud.com/jamesaustinmoore/listen-to-your-bees">Eddie Woods: Listen to your Bees von JamesAustinMoore (soundcloud.com)</a></li>
      <li><a href="http://www.dave-cushman.net/elect/apidictorarticle.html">Apidictor Article From Bee Craft By Eddie Woods (dave-cushman.net)</a></li>
      <li><a href="https://www.beesource.com/threads/apidictor.365768/">Apidictor | Beesource Beekeeping Forums</a></li>
    </ul>
    </p>

    <p>Inzwischen gibt es ein breites Spektrum von Artikeln, Webseiten und andere Quellen, die sich mit den Geräuschen in einem Bienenstock und ihrer Analyse beschäftigen. Siehe:
    <ul>
      <li><a href="https://docs.google.com/document/d/1LJM6puevz3vdOQmAsb04eZ2hK3r9drmYUNvWFERea0U/preview">Bee sound analysis - literature overview - Google Docs</a></li>
    </ul>
    </p>

    <p>Unser Ansatz zielt darauf ab, durch eine möglichst einfache Fragestellung und mit Mitteln, die jedem Imker zur Verfügung stehen, das Bienensummen zu analysieren und mittels künstlicher Intelligenz zu ermitteln, ob sich das Volk in Schwarmstimmung befindet oder nicht.</p>

  <p>Wer sich näher für Sound-Klassifizierung interessiert und eventuell eigene Versuche machen möchte, sei auf folgenden Link hingewiesen:
  <ul>
    <li><a href="https://docs.edgeimpulse.com/docs/tutorials/audio-classification">https://docs.edgeimpulse.com/docs/tutorials/audio-classification</a></li>
  </ul>
  </p>

  <p>Da uns bisher kaum Audiomaterial von Bienenvölkern in Schwarmstimmung zur Verfügung stand, haben wir versuchsweise untersucht, ob das Bienensummen verschiedener Völker, die sich nicht in Schwarmstimmung befinden, charakteristische Eigenschaften aufweist, die sich durch Sound-Klassifizierung unterscheiden lassen.</p>

  <p>Diese Untersuchung war erfolgreich. Die Klassifizierung mittels künstlicher Intelligenz hatte eine Trefferquote von über 90%.</p>

  <p>Um mit dem Projekt fortzufahren, benötigen wir eine große Menge Soundproben sowohl von Bienenvölkern mit als auch von Bienenvölkern ohne Schwarmstimmung. Wir würden uns deshalb freuen, wenn möglichst viele Imker uns möglichst viele Sound-Files beider Klassen zur Verfügung stellen würden. Wichtig wäre dabei, dass die Proben möglichst fehlerfrei der einen oder anderen Klasse zugeordnet werden könnten.</p>

  <p>Wir selbst gehen bei der Aufnahme solcher Sound-Files wie folgt vor:
  <ol>
    <li>Ca. 15 Minuten vor der Untersuchung eines Volkes auf Schwarmstimmung starten wir den Rekorder unseres Smartphones und sprechen zunächst das aktuelle Datum und unsere interne  Bezeichnung des zu untersuchenden Volkes darauf. Dann legen wir das Smartphone, geschützt durch eine Papier- oder Plastiktüte, bei weiterlaufendem Rekorder zwischen Deckel und Folie der Beute. Der Deckel wird nur lose aufgelegt. Wenn dadurch der Deckel nicht mehr richtig schließt, schadet dass nicht. Unsere spätere Klassifizierung wird durch die vermehrten Fremdgeräusche nicht beeinträchtigt.</li>
    <li>Sobald wir mit der Untersuchung des Volkes beginnen, legen wir das Smartphone an einen sicheren Ort zur Seite, während der Rekorder weiterläuft, bis wir die Untersuchung auf Schwarmstimmung beendet haben.</li>
    <li>Danach sprechen wir das Ergebnis auf den Rekorder nach folgenden Kategorien:
      <ul>
        <li>„Das Volk hat keine Weiselzellen“</li>
        <li>oder</li>
        <li>„Das Volk hat offene Weiselzellen mit Stiften“</li>
        <li>oder</li>
        <li>„Das Volk hat offene Weiselzellen mit Maden“</li>
        <li>oder</li>
        <li>„Das Volk hat verdeckelte Weiselzellen.“</li>
      </ul>
    </li>

    <li>Sodann unterbrechen wir die die Aufnahme und speichern sie ab.  Den Namen des Files setzen wir aus dem aktuellen Datum und der internen Bezeichnung des Volkes zusammen.</li>
    <li>In den folgenden ca. 15 Minuten untersuchen wir weitere Völker, beginnen aber vorher mit der nächsten Rekorderaufnahme auf dem Volk, das in etwa 15 Minuten an der Reihe sein wird usw.</li>
  </ol>
  </p>
  <p>Die so aufgezeichneten Files können dann auf der Seite <router-link to="/">Home</router-link> hochgeladen werden.</p>
  </div>
</template>

<style>
.about {
  max-width: 50em;
  margin: 0 auto;
  text-align: left;
  font-size:16pt;
}

ol > li {
  list-style: decimal;
}
</style>
